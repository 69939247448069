<template lang="pug">
	#sections
		#section-1
			.widget-container
				el-row(:gutter="24")
					el-col(:xs="24")
						el-card.box-card
							.clearfix.d-flex.j-between(slot="header")
								h2.no-line-height.text-left Groups
								.extra
									el-tooltip(class="item" effect="dark" content="Add New Group" placement="top-start")
										font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable.text-info(:icon="['fa', 'plus']" @click="$router.push('/group/create')")

							el-row.mar.t-1(:gutter="24")
								el-col(:xs="24" :sm="8")
									el-input(v-model="paginate.keyword" size="mini" placeholder="Search" v-debounce:300ms="fetchData")
							el-divider
							el-row.mg.b0-5()
								el-col(:span="24")
									el-pagination.mar.a-1.text-left(background="" 	@size-change="fetchData" @current-change="fetchData" :current-page.sync="paginate.page" :page-sizes="[10, 20, 30, 40]" :page-size.sync="paginate.limit" layout="prev, pager, next, sizes " :total="count")
							el-table(id="data-list", v-loading='busy', :data='list', element-loading-text='Loading', border='', fit='', highlight-current-row='')
								el-table-column(align='center', label="id" width='180')
									template(slot-scope='scope')
										span {{ scope.row.id }}
								el-table-column(align='center', label="Name")
									template(slot-scope='scope')
										span {{ scope.row.name || '-' }}
								el-table-column(align='center', label="Subscribers" )
									template(slot-scope="scope")
										span {{ scope.row.leads.length }}
								el-table-column(align='center', label="Creation Date" )
									template(slot-scope="scope")
										span {{ scope.row.createdAt | formatDateTimeShort }}
								el-table-column(align='center', label="Actions" :width="100")
									template(slot-scope='scope')
										el-tooltip(v-if="scope.row.leads.length > 0" class="item" effect="dark" content="Send signal" placement="top-start")
											font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'pencil-alt']"  @click="action('signal', scope.row)")
										el-tooltip(class="item" effect="dark" content="Edit Group" placement="top-start")
											font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'user-alt']"  @click="$router.push('/group/edit/' + scope.row.id)")
										el-tooltip(class="item" effect="dark" content="Show messages" placement="top-start" )
											font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'list']"  @click="action('messages', scope.row)")
										el-tooltip(class="item" effect="dark" content="Remove Group" placement="top-start")
											font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'trash-alt']" @click="deleteGroup(scope.row.id)")
		#section-signal
			el-drawer.text-left( :visible.sync='showSendSignalDrawer', :with-header='true')
				template(slot="title")
					h1 Notify the subscribers with a signal
				el-form.pd.a1(:form="signal_form")
					el-row(:gutter="12")
						el-col(:span="24")
							el-form-item(label="Turn to suspended message")
								el-radio-group.text-left(v-model="signal_form.suspend_sending")
									el-radio(:label="true") On
									el-radio(:label="false") Off
							el-form-item(v-if="signal_form.suspend_sending" label="Suspended to")
								el-date-picker.w-100(:picker-options="pickerOptions" placeholder="Subscription Deadline" v-model="signal_form.suspend_sending_date" format="yyyy/MM/dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss" type="datetime")

						el-col(:span="24")
							//h2 Form to send signals
							p Use this form to send signals to the subscribers
						el-col(:span="24")
							el-form-item(label="Content")
								el-input(type="textarea" :rows="10" v-model="signal_form.content" :maxlength="4096" show-word-limit="")
				.pd.a1
					el-button(type="primary" @click="sendNotification") Send
					el-button(type="danger" @click="action('signal', null, false)") Cancel
				//wysiwyg-editor(@content="($event) => this.signal_form.content = $event")

		#section-leads
			el-dialog.override-width.search-dialog(v-if="currentEntry" :visible="showGroupLeadsDialog" @close='showGroupLeadsDialog = false')
				template(slot="title")
					h1 Group Leads
						el-row(:gutter="24")

		#section-messages
			el-dialog.override-width.search-dialog(v-if="currentEntry" :visible="showMessagesDialog" @close='showMessagesDialog = false')
				template(slot="title")
					h1 Lead Messages
						el-row(:gutter="24")
				el-row
					el-col(:xs="24")
						.infinite-list-wrapper(style="overflow:auto;" v-bind:class="{'half-screen':currentEntry.msg.length > 0}")
							//div.infinite-list( v-infinite-scroll="load" infinite-scroll-distance="500px")
							div(v-for="c of (currentEntry.msg)" )
								el-card.comments-container.mg.a0-5.text-left( shadow="hover" cover="" v-bind:id="c.id")
									template(slot="header")
										span <b>ID:</b>  {{c.ext_id}}, <b>Scope: </b>{{c.scope}}
									//label  <b>Topic: </b>{{c.topic}}
									p.text-left  {{ c.content }}
									.d-flex.justify-content-between
										//span
												b Created By:&nbsp;
												| {{ c.user.first_name + ' ' + c.user.last_name }}
										small <b>Timestamp:</b> {{ c.timestamp | formatDateTime }}
</template>

<style lang="scss">

</style>

<script>
import {DeleteEntity, GetList} from "@/api/group";
import {SendMessageToAll} from "@/api/group";
import {Unsubscribe} from "@/api/lead";


export default {
	data() {
		return {
			fetchWatcher: null,
			showSendSignalDrawer: false,
			showGroupLeadsDialog: false,
			showMessagesDialog: false,
			busy: false,
			list: [],
			count: 0,
			paginate: {
				limit: 10,
				page: 1,
				keyword: ''
			},
			currentEntry: null,
			signal_form: {
				content: '',
				suspend_sending: false,
				suspend_sending_date: ''
			},
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 86400000;
				}
			}
		}
	},
	created() {
		this.fetchData();
		this.fetchWatcher = setInterval(() => this.fetchData(), 10000);
	},
	beforeDestroy() {
		clearInterval(this.fetchWatcher);
	},
	methods: {
		fetchData() {
			GetList(this.paginate.limit, this.paginate.page, this.paginate.keyword)
					.then(res => {
						this.list = res.list;
						this.count = res.count;
					});
		},
		action(type, obj = null, status = true) {
			switch(type) {
				case 'signal': this.showSendSignalDrawer = status; this.currentEntry = obj; break;
				case 'leads': this.showGroupLeadsDialog = status; this.currentEntry = obj; break;
				case 'messages': this.showMessagesDialog = status; this.currentEntry = obj; break;
			}
		},
		sendNotification() {
			let loader = this.$loading.show();
			SendMessageToAll(this.currentEntry.id, this.signal_form.content, { suspend_sending: this.signal_form.suspend_sending, suspend_sending_date: this.signal_form.suspend_sending_date })
					.then(res => {
						console.log(res);
						if(!res) return this.$notify.error({title: 'API Error', message: 'Sending failed, please, try again'});
						// this.$notify.success({title: 'API Response', message: 'The message was successfully sent'});
						this.signal_form = { content: '', suspend_sending_date: '', suspend_sending: false};
						this.showSendSignalDrawer = false;
						this.fetchData();
						loader.hide();
					})
					.catch(e => {
						console.error(e);
						loader.hide();
					});
		},
		deleteGroup(guid) {
			const that = this;
			this.$confirm('Are you sure that you want to delete this group? This action cannot be undone!', 'Warning', {
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(async () => {
				let loader = this.$loading.show();
				try {
					let result = await DeleteEntity(guid);
					this.fetchData();
					this.$notify.success({
						title: 'Action Status',
						message: 'Group Removed'
					});
				} catch (e) {
					that.$notify.warning({
						title: 'Action Status',
						message: 'Group Delete failed. Error: ' + e.message
					});
				}
				loader.hide();
			}).catch(err => {
				this.$notify.warning({
					title: 'Action Status',
					message: 'Group delete canceled'
				});
			})
		},
	}

}
</script>
